import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "srcdoc"]

import { computed, ref, reactive, onBeforeMount, watch } from 'vue';
import { useStore } from 'vuex';
import { NavigationFailure, useRouter } from 'vue-router';

// Components - Import
import { BaseContainer, BaseColumn, BaseGrid } from '@/components/primitives';
import { BaseText, BaseButton, BaseSpacer, LoadingSpinner } from '@/components/partials';
import { VerticalTable, Modal } from '@/components/containers';
import { BaseForm, BaseInput, StandAloneLabel } from '@/components/partials/forms';

// Constants - Import
import { StockStatus } from '@/constants/statuses/orderLineStatuses';
import ShippingAddonNames from '@/constants/shippingAddons/shippingAddonNames';

// Helpers - Import
import { HelperFormErrors } from '@/helpers/form';

// Types - Import
import type { ComputedRef, Ref } from 'vue';
import type { TableRow } from '@/types/table';
import type { ProductItem } from '@/types/product';

// Types - Local
import useOrder from '@/@use/order';
import useOrderLines from '@/@use/orderLines';
import store from '@/store';
import OrderProcessTypes from '@/constants/orderPriority/orderProcessTypes';
import outgoingOrder from '@/store/modules/outgoingOrder';

// @use - Envoke
interface PackingBinForm {
  binNumber: string;
  errors: HelperFormErrors;
}

// @use - Import
const labelInjectedStyles = `
  html, body {
    page-break-after: avoid !important;
    page-break-before: avoid !important;
    height: 99%;
  }
  body > div { 
    height: 400px !important;
    page-break-after: avoid !important;
    page-break-before: avoid !important;
  }
  @page {
    size: 10.2cm;
  }
  div { 
    box-sizing: border-box; 
    border: 1px solid transparent;
  }
`;

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

// Vue - Import
const router = useRouter();
const { deliveryMethod, userNo, courier, shippingMethod } = useOrder();
const { products, productCount } = useOrderLines();

onBeforeMount(() => {
  if (!products?.value?.length) {
    store.dispatch('outgoingOrder/getAssignedItem', OrderProcessTypes.PACKING);
  }
});

// State
const state = reactive({
  iframeHeight: '400px',
});

// Store[state]
const loadingLabel: ComputedRef<boolean> = computed(() => store.getters['outgoingOrder/getStoreLoading']);
const deliveryLabel: ComputedRef<string> = computed(() => store.state.outgoingOrder.deliveryLabel);
const deliveryLabelCourier: ComputedRef<string> = computed(
  () => store.state.outgoingOrder.deliveryLabelCourier
);

// Watchers
watch(deliveryLabel, () => {
  if (deliveryLabel.value)
    setTimeout(() => {
      state.iframeHeight = 'auto';
    }, 500);
  else state.iframeHeight = '400px';
});

// Store[Actions]
const getDeliveryLabel = async (): Promise<void> => await store.dispatch('outgoingOrder/getDeliveryLabel');
const completeOrder = async (): Promise<void | NavigationFailure> => await router.push({ name: 'Packing' });

//Print label
const labelFrame: Ref<HTMLIFrameElement | null> = ref(null);
const printDPDLabel = (): void => {
  const frame: Window = (labelFrame.value as HTMLIFrameElement).contentWindow as Window;
  const styleTag: HTMLElement = document.createElement('style');
  styleTag.appendChild(document.createTextNode(labelInjectedStyles));
  frame.document.head.appendChild(styleTag);
  frame.focus();
  frame.print();
};

// Table data
const isTableDateLoaded: Ref<boolean> = ref(true);
const outOfStockProductsCount: ComputedRef<number> = computed(
  () => products.value?.filter((product: ProductItem) => product.status !== StockStatus.PACKED).length
);
const completedPicksDetail: ComputedRef<string> = computed(
  () => `(${outOfStockProductsCount?.value} with issues)`
);
const tableRows: ComputedRef<TableRow[]> = computed(() => {
  return [
    {
      title: {
        content: 'User No',
      },
      columns: [
        {
          content: userNo.value,
          color: 'default',
        },
      ],
    },
    {
      title: {
        content: 'Courier',
      },
      columns: [
        {
          content: courier.value,
          color: 'default',
        },
      ],
    },
    {
      title: {
        content: 'Shipping Method',
      },
      columns: [
        {
          content: shippingMethod.value,
          color: 'default',
        },
      ],
    },
  ];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseContainer), null, {
    default: _withCtx(() => [
      _createVNode(_unref(BaseGrid), null, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseColumn), null, {
            default: _withCtx(() => [
              _createVNode(_unref(VerticalTable), {
                rows: tableRows.value,
                "is-loaded": isTableDateLoaded.value
              }, null, 8, ["rows", "is-loaded"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(BaseSpacer)),
      _createVNode(_unref(BaseGrid), null, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseColumn), null, {
            default: _withCtx(() => [
              _createVNode(_unref(Modal), {
                title: "Print Label",
                "open-button-text": deliveryLabel.value ? 'Show label' : 'Print delivery label',
                fullscreen: true,
                "onModal:open": getDeliveryLabel
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(BaseGrid), null, {
                    default: _withCtx(() => [
                      (loadingLabel.value)
                        ? (_openBlock(), _createBlock(_unref(BaseColumn), {
                            key: 0,
                            justify: { default: 'center' },
                            align: { default: 'center' }
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(LoadingSpinner))
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createVNode(_unref(BaseColumn), {
                              span: { tablet: deliveryLabelCourier.value === 'dpd' ? '6' : '12' }
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("iframe", {
                                  class: "iframemargins",
                                  src: 
                    deliveryLabelCourier.value !== 'dpd'
                      ? `data:application/pdf;base64,${deliveryLabel.value}`
                      : undefined
                  ,
                                  srcdoc: deliveryLabelCourier.value === 'dpd' ? deliveryLabel.value : undefined,
                                  ref_key: "labelFrame",
                                  ref: labelFrame,
                                  title: "PDF in an i-Frame",
                                  frameborder: "0",
                                  scrolling: "auto",
                                  width: "100%",
                                  height: "400px"
                                }, null, 8, _hoisted_1),
                                _createVNode(_unref(BaseSpacer))
                              ]),
                              _: 1
                            }, 8, ["span"]),
                            (deliveryLabelCourier.value === 'dpd')
                              ? (_openBlock(), _createBlock(_unref(BaseColumn), {
                                  key: 0,
                                  span: { tablet: '6' }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(BaseButton), {
                                      onClick: printDPDLabel,
                                      color: "success"
                                    }, {
                                      default: _withCtx(() => _cache[0] || (_cache[0] = [
                                        _createTextVNode("Print")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ], 64))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["open-button-text"])
            ]),
            _: 1
          }),
          (deliveryLabel.value)
            ? (_openBlock(), _createBlock(_unref(BaseColumn), { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_unref(BaseSpacer), { size: "0-5" }),
                  _createVNode(_unref(BaseButton), {
                    onClick: completeOrder,
                    color: "success"
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Complete order")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})